import React, { createContext, useContext, useState } from "react";

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider

function QuizStateProvider({children}) {

  const [quizState, setQuizState] = useState({
    pay: 0,
    education: 0,
    workLife: 0,
    community: 0,
    medical: 0,
  });

  return <LocalStateProvider value={{quizState,setQuizState}}>{children}</LocalStateProvider>
}


function useQuizState() {
  const all = useContext(LocalStateContext)
  return all
}


export {QuizStateProvider, useQuizState}
