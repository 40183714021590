import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const HEADER_QUERY = graphql`
  query HEADER_QUERY {
    prismicRnLandingPa {
      data {
        logo {
          gatsbyImageData(
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;



const Header = () => {
  const {prismicRnLandingPa: {data}} = useStaticQuery(HEADER_QUERY);
  const image = getImage(data.logo)
  
  return (
    <header className="header max-width">
      <GatsbyImage image={image} alt="KRMC Logo" />
    </header>
  )
}

export default Header
