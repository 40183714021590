import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FOOTER_QUERY = graphql`
  query FOOTER_QUERY {
    prismicRnLandingPa {
      data {
        footer_logo {
          gatsbyImageData(placeholder: BLURRED)
        }
        footer_links {
          link {
            url
          }
          link_text {
            text
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(FOOTER_QUERY);
  const image = getImage(data.footer_logo);


  return (
    <footer className="footer">
      <div className="footer__top">
        <a href="https://www.kearneyregional.com/">
        <GatsbyImage image={image} alt="KRMC Logo" />
        </a>
        <menu>
          {data.footer_links.map((item, index) => (
            <a key={index} href={item.link.url}>
              {item.link_text.text}
            </a>
            // <p>testing</p>
          ))}
          <a href="tel:(308) 455-3600">(308) 455-3600</a>
        </menu>
      </div>
      <div className="footer__bottom">
        Keraney Regional Medical Center © {new Date().getFullYear()} | All Right
        Reserved |{" "}
        <a href="https://www.kearneyregional.com/privacy-policy/">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
